import {TeamAnglerViewModel, TeamViewModel} from '@bluewater/viewmodels';
import {Dictionary}                                  from '@gx/core';
import {dateHasValue}      from './date-functions';
import {ValidationMessage} from './validation.message';

const newAnglerJson = JSON.stringify(createNewAngler());

export function isNewUnmodifiedAngler(angler: TeamAnglerViewModel | null | undefined) {
    return JSON.stringify(angler) === newAnglerJson;
}

export const anglerReleaseInvalidId = 'anglerReleaseInvalidId';

export function createNewAngler(): TeamAnglerViewModel {
    return {
        AnglerId: 0,
        PreviousAnglerId: undefined,
        Address: '',
        Age: 0,
        CellPhone: '',
        City: '',
        DateOfBirth: {Year: 0, Month: 0, Day: 0},
        Email: '',
        FirstName: '',
        HomePhone: '',
        Income: 0,
        IsCaptain: false,
        IsOwner: false,
        IsSka: false,
        LastName: '',
        State: '',
        StatusClassification: '',
        StatusId: 0,
        TShirtSize: 0,
        Zip: '',
        ReleaseSignature: ''
    };
}

function anglerCountable(angler: TeamAnglerViewModel): boolean {
    return angler.StatusId == 8 || angler.StatusId == 9;

}

export function countCrewMembers(team: TeamViewModel): number {
    return team.Anglers.filter(anglerCountable).length;
}

export const anglerCrewMax = 8;

export function validateTeamAnglers(team: TeamViewModel | null | undefined, validationMessages: ValidationMessage[]) {
    if (!team) {
        return false;
    }

    const hasOwner = team.Anglers.some(angler => angler.IsOwner);
    if (!hasOwner) {
        validationMessages.push({message: 'The team owner must be specified.'});
    }

    const hasCaptain = team.Anglers.some(angler => angler.IsCaptain);
    if (!hasCaptain) {
        validationMessages.push({message: 'The team captain must be specified.'});
    }

    
    // const crewMemberCount = countCrewMembers(team);
    // const crewCountIsValid = crewMemberCount <= anglerCrewMax;
    // if (!crewCountIsValid) {
    //     validationMessages.push({message: `The maximum number of crew members is ${anglerCrewMax}.`});
    // }

    team.Anglers.forEach(angler => {
        const subMessages: string[] = [];
        validateAngler(angler, subMessages);

        if (subMessages.length) {
            validationMessages.push({
                message: `Angler ${angler.FirstName} ${angler.LastName} is not valid.`,
                subMessages
            });
        }
    });

    function justLoweredAlpha(value: string) {
        const pattern = /[a-z]/gi;
        return value!.match(pattern)!.join('').toLowerCase();
    }

    function anglerReleaseSignatureIsValid(angler: TeamAnglerViewModel): boolean {
        if (!angler || !angler.ReleaseSignature || !angler.FirstName || !angler.LastName) {
            return false;
        }

        const expectedName = justLoweredAlpha(angler.FirstName + angler.LastName);
        return justLoweredAlpha(angler.ReleaseSignature) === expectedName;
    }

    const releaseSignaturesAreValid = team.Anglers.every(anglerReleaseSignatureIsValid);
    if (!releaseSignaturesAreValid) {
        validationMessages.push({
            id: anglerReleaseInvalidId,
            message: 'All anglers must sign the participant release.',
            subMessages: []
        });
    }

    return !validationMessages.length;
}

function checkRequiredFields(requiredFields: string[], angler: TeamAnglerViewModel, validationMessages: string[]) {
    const anglerFields = angler as unknown as Dictionary<string, string>;

    requiredFields
        .forEach(requiredFieldName => {
            if (!anglerFields[requiredFieldName]) {
                validationMessages.push(requiredFieldName + ' is a required field.');
            }
        });
}

export function validateAngler(angler: TeamAnglerViewModel, validationMessages: string[]) {
    if (!angler) {
        return true;
    }

    const anglerFields = angler as unknown as Dictionary<string, string>;

    if (isNewUnmodifiedAngler(angler)) {
        return false;
    }

    if (!angler.StatusId) {
        validationMessages.push('Status is a required field.');
    }

    checkRequiredFields(['FirstName', 'LastName'], angler, validationMessages);

    if (angler.IsCaptain || angler.IsOwner) {
        checkRequiredFields(['Email', 'Address', 'City', 'State', 'Zip'], angler, validationMessages);

        if (!angler.CellPhone && !angler.HomePhone) {
            validationMessages.push('Phone number must be specified.');
        }
    }

    // validate values are in proper form
    const dob = angler.DateOfBirth;
    const dateOfBirthIsValid = !!(dob && dob.Year && dob.Month && dob.Day);
    if (dateHasValue(dob) && !dateOfBirthIsValid) {
        validationMessages.push('Date Of Birth must be a valid value.');
    }

    validatePhoneNumberValue('Home Phone', angler.HomePhone, validationMessages);
    validatePhoneNumberValue('Cell Phone', angler.CellPhone, validationMessages);
    validateZipCodeValue('Zip Code', angler.Zip, validationMessages);

    const anglerEmail = angler.Email || '';
    const anglerEmailDomain = anglerEmail.split('@')[1];

    if (anglerEmail && (!anglerEmailDomain || anglerEmailDomain.length < 3)) {
        validationMessages.push('Email address is invalid.');
    }

    return !validationMessages.length;
}

export function phoneNumberIsValidUnitedStatesNumber(phoneNumber?: string): boolean {
    if (!phoneNumber) {
        return true;
    }

    return countDigits(phoneNumber) === 10;
}

function countDigits(value: string) {
    if (!value) {
        return 0;
    }
    const matches = value.match(/\d/g);

    if (!matches) {
        return 0;
    }
    return matches.length;
}

export function validatePhoneNumberValue(label: string, phoneNumber: string, validationMessages: string[]) {
    const isValid = phoneNumberIsValidUnitedStatesNumber(phoneNumber);
    if (!isValid) {
        validationMessages.push(label + ' must have 10 digit phone number');
    }
}

export function valueIsValidZipCode(value: string) {
    if (!value) { return true; }
    const digitCount = countDigits(value);
    // zip codes have 5 digits, zip+4 has 9 digits
    return digitCount === 5 || digitCount === 9;
}

export function validateZipCodeValue(label: string, zipCode: string, validationMessages: string[]) {
    const isValid = valueIsValidZipCode(zipCode);
    if (!isValid) {
        validationMessages.push(label + ' must have 5 (or 9) digits.');
    }
}
