<bw-step-header stepName="Participants" [stepNumber]="3" [totalStepCount]="5" [tournamentName]="tournamentName">
</bw-step-header>

<!--
<div *ngIf="!editAnglerMode && !hasAnglers" class="validation-message validation-warning">
    <div>
        The maximum number of crew members allowed is {{ maxCrewMembers }}. 
    </div>
</div>
-->

<div *ngIf="!editAnglerMode && validationMessages?.length" class="validation-message">
    <div *ngFor="let message of validationMessages">
        {{ message.message }}
        <span class="more">
            {{ message.subMessages && message.subMessages.length ? ' [ More... ]' : '' }}
        </span>
        <div class="subMessage" *ngIf="message.subMessages && message.subMessages.length">
            <div *ngFor="let subMessage of message.subMessages">
                {{ subMessage }}
            </div>
        </div>
    </div>
</div>

<bw-angler-edit *ngIf="editAnglerMode && currentAngler" [lookups]="lookups" [tournament]="tournament"
    [value]="currentAngler"></bw-angler-edit>

<div *ngIf="!currentAngler" class="anglers">
    <bw-angler-header [anglerWaiver]="tournament.AnglerWaiver"
        [anglerWaiverValid]="anglerWaiverValid"></bw-angler-header>
    <bw-angler *ngFor="let angler of value!.Anglers" [angler]="angler"></bw-angler>
    <bw-angler-copy *ngFor="let angler of previousAnglers | gxPropertyEqualFilter:{property: 'CopyStatus', ne: '2'} "
        [teamId]="value!.TeamId" [angler]="angler"></bw-angler-copy>
    <button (click)="addNewAngler()">Add New</button>
</div>

<div class="filler">
    &nbsp;
</div>

<div class="release-notice">
    <div>* -&nbsp;</div>
    <div>
        by typing their first and last name in the provided text box the captain, owner, angler or any occupant of the
        boat agrees to be bound by the terms and conditions of the
        <a [href]="tournament.AnglerWaiver" target="release">
            Participant Release
        </a>.<br />
        Failure to sign the
        <a [href]="tournament.AnglerWaiver" target="release">
            Participant Release
        </a>
        by all members of a competing team will disqualify the team from
        competition.
    </div>
</div>